<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="version-content" class="container">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <th scope="row">Version</th>
              <td id="version">{{ version }}</td>
            </tr>
            <tr>
              <th scope="row">Version date</th>
              <td id="version-date">2024-10-02 10:39:10 EDT</td>
            </tr>
            <tr>
              <th scope="row">Environment name</th>
              <td id="environment-name">{{ environmentName }}</td>
            </tr>
            <tr>
              <th scope="row">Build number</th>
              <td id="build-number">3</td>
            </tr>
            <tr>
              <th scope="row">Git branch</th>
              <td id="git-branch">project_moonshiners_defects</td>
            </tr>
            <tr>
              <th scope="row">Git commit hash</th>
              <td id="git-commit">c6b569b09da1ea8ad8dcc5141a4d3fb39dc4b572</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>
